import * as React from "react"
import type { HeadFC } from "gatsby"
import styled from "@emotion/styled"
import { gs } from "../../components/styling/GlobalStyles"
import Seo from "../../components/Seo"
import Layout from "../../components/Layout"
import { SectionWrapper, Node } from "../../components/Sections"
import { HeadingSection, HeadingTitle } from "../../components/styling/Heading"
import BorderWrapper from "../../components/styling/BorderWrapper"
import { StaticImage } from "gatsby-plugin-image"
import Zoom from "react-medium-image-zoom"

const title = "Personalized Documentation"

const headings = [
  { title: "Summary", id: "Summary" },
  { title: "Some Explanation", id: "Some-Explanation" },
  { title: "Maximizing Convenience", id: "Maximizing-Convenience" },
  { title: "Defining A Note", id: "Defining-A-Note" },
  { title: "Self-Accommodations", id: "Self-Accommodations" },
  { title: "Results", id: "Results" },
  { title: "Retrospective Thoughts", id: "Retrospective-Thoughts" },
]

const summmary: Summary = {
  headings: headings,
  details: [
    "Solo Project",
    <>
      Project Launch, <b>2022</b>
    </>,
    "~15 Minute Read",
    <a href="https://dev-notes-demo.herokuapp.com/">Dev Notes Demo</a>,
  ],
  role: ["UI / UX Designer", "Web Developer"],
  tools: ["Figma", "CSS", "React / JavaScript", "Node.js"],
  extraContent: (
    <Node.multiCol
      col1={
        <BorderWrapper>
          <Zoom>
            <StaticImage
              src="../../images/devnotes/DevNotes5.jpg"
              alt="The front page of a Dev Notes instance with a basic header, a simple form, a section with a Tag filters and search, and two placeholder example notes that had been saved below. The header has one link called Dev Notes. The form has one submission button and four fields: a basic text field called Topic, a larger textarea field called Solution, an autocompleting select field to add additional Solutions, an additional autocompleting select field to create or add Tags. The submission button at the bottom currently displays the error: A Topic is required to create a Note. Only the titles and Tags of the two placeholder notes can be seen. One of which is Tyrannosaurus with the Tag Cretaceous, and the other is Camarasaurus with the Tag Jurassic."
              loading="eager"
            />
          </Zoom>
        </BorderWrapper>
      }
      col2={
        <BorderWrapper>
          <Zoom>
            <StaticImage
              src="../../images/devnotes/DevNotes4.jpg"
              alt="Another view of the front page of a Dev Notes, this time highlighting three Tag filters, and four example notes. The Tag filters are Dino Ipsum, Jurassic, and Cretaceous.. All of the text in the Notes are randomly generated real and fake dinosaur names. Each of the four Notes are are roughly the same size, and they all look very similar to the wireframes described earlier. The only addition is a new middle button in the actions at the bottom of a note. This new button is an icon with an arrow implying to expand or collapse the note vertically. The only note out of the four that stands out in any relevant way is the first one, as it's the only one that has been vertically expanded, thus making it the longest"
              loading="eager"
            />
          </Zoom>
        </BorderWrapper>
      }
    />
  ),
}

const Ul = styled.ul`
  max-width: calc(${gs.size.textWidth} - 10px);
  line-height: 1.4;
  padding: 0 10px 0 20px;
  margin: 10px 0;
  @media all and (min-width: ${gs.size.screen.md}) {
    max-width: calc(${gs.size.textWidth} - 20px);
    padding: 0 10px 0 30px;
  }
`

const DevNotesPage = () => {
  return (
    <>
      <Layout>
        <SectionWrapper
          heading={
            <HeadingTitle
              name={title}
              subtitle="/overviews/Dev-Notes"
              id={headings[0].id}
              summary={summmary}
            />
          }
        >
          <Node.p>
            As a developer, I often find myself searching for the same solutions
            over and over again, but documenting how I've solved difficult
            problems has been immensely helpful. That being said, documenting
            what I've already solved isn't the most exciting work, and I
            struggle to do it if it's not excessively convenient. I created Dev
            Notes to hit the specific balance I wanted between simple note
            taking, but detailed enough to find the notes when I needed them.
          </Node.p>
          <SectionWrapper
            heading={
              <HeadingSection id={headings[1].id}>
                {headings[1].title}
              </HeadingSection>
            }
          >
            <Node.p>
              Prior to making Dev Notes, I had a giant google doc that I would
              add previously solved solutions to, and used Ctrl+F to find them.
              It did the job okay, but I didn't like how it looked and it was
              becoming quite difficult to maintain. I figured there had to be a
              tool that I could replace it with, but I found myself having
              either of these two complaints:
            </Node.p>
            <Ul>
              <li>
                The tool was too simple, making it difficult to find solutions
                again.
              </li>
              <li>
                The tool was too complex, so while it might be easy to find
                solutions when I needed them, I'd never take the time to save
                solutions in the first place.
              </li>
            </Ul>
            <Node.p>
              Browser Bookmarks, for example, are the fastest method of saving a
              link, which does a good job at saving most solutions. But with
              only a single text field to describe the link there's a slim
              chance you'll always remember which links are for which solutions.
              On the other end, Wordpress allows a blogpost worth of detail, and
              has loads of free plugins and themes; all of which would make for
              an easy solution if not for the unavoidably large amount of time
              and input it takes to navigate between viewing and saving content.
              After I felt I had adequately — but unsuccessfully — hunted for
              the right tool, I decided to make my own. I wanted a tool that:
            </Node.p>
            <Ul>
              <li>
                allowed for saving solutions on the same page they were viewed.
              </li>
              <li>
                allowed solutions to be detailed enough to be easily found when
                they're needed.
              </li>
              <li>
                presents solutions in a simple format with a color palette I
                liked.
              </li>
            </Ul>
          </SectionWrapper>
          <SectionWrapper
            heading={
              <HeadingSection id={headings[3].id}>
                {headings[3].title}
              </HeadingSection>
            }
          >
            <Node.p>
              My biggest complaint about my large Google doc was having to rely
              on headings to separate content, which limited my ability to
              organize the doc effectively. Most of the time, this was not an
              issue because I could search the doc for what I wanted to find.
              However, I could not always rely on myself to quickly write
              solutions in a way that I would remember how to find later. I
              tried to categorize my solutions into separate sections of the
              doc, but this meant I had to scan the doc for the best place to
              add a new solution, making the process significantly less
              convenient. From this, I concluded that having a pre-defined set
              of fields for each entry of content would be better. I called
              these individual content entries <b>Notes</b>. Here are the fields
              of a Note that I ended up sticking with:
            </Node.p>
            <Ul>
              <li>
                <b>Category</b> - Every Note belongs to a Category. To minimize
                the input required to save a new Note, the user creates and
                selects a Category before they are able to save a new note.
              </li>
              <li>
                <b>Topic</b> - the title of a Note.
              </li>
              <li>
                <b>Solution</b> - the body text field that contains the Solution
                of a topic.
              </li>
              <li>
                <b>Tags</b> - The only optional field of the bunch. Tags are
                available to further assist in organizing topics and give an
                alternative method to find Solutions in case I knew I had a
                Solution, but couldn't recall what its Topic was.
              </li>
            </Ul>
            <Node.p>
              From there I made some wireframes to envision how this might look:
            </Node.p>
            <Node.multiCol
              style={{ margin: "10px 0 20px 0" }}
              col1={
                <BorderWrapper caption="The overall look that's focused on the Note submission form.">
                  <Zoom>
                    <StaticImage
                      src="../../images/devnotes/DevNotes_Wireframe.jpg"
                      alt="A rough mockup wireframe of the overall look of Dev Notes. It has very basic formatting overall with a header, Note entry form, search bar, and two example Notes (in that order). The header has three links of Category A, B, and C. The form has one submission button and three fields: a basic text field called Topic, a larger textarea field called Solution, and an autocompleting select field called Tags."
                    />
                  </Zoom>
                </BorderWrapper>
              }
              col2={
                <BorderWrapper caption="A highlighted view of an individual Note.">
                  <Zoom>
                    <StaticImage
                      src="../../images/devnotes/ExampleWireframeNote.png"
                      alt="A rough mockup of an individual Note separated into a title section, a body section, and an actions section. The title has the text 'Example Note With Formatting' as well as a smaller piece of text representing a Tag named C#. The body has a bunch of placeholder text showing the various text formatting available that includes hyperlinks, inline code, code blocks, and one size of heading. The actions section shows two simple buttons that have the text Expand, and Edit."
                    />
                  </Zoom>
                </BorderWrapper>
              }
            />
            <Node.default style={{ padding: "0" }}>
              <Node.col
                style={{ margin: "20px 0" }}
                as="aside"
                heading="Side Note (Pun Intended)"
                body={
                  <>
                    I spent a bunch of time deliberating whether including a
                    Tags field was beneficial. If it was required, it would have
                    a negative impact on overall convenience, but if it was
                    optional, would I even use it? In the end, I decided that
                    because it did not take up much space visually, it was worth
                    implementing as an optional field to see if it solved any
                    problems I was currently unaware of. If this wasn't a small
                    project for myself it sounded like a perfect case for some
                    A/B usability testing.
                  </>
                }
              />
            </Node.default>
          </SectionWrapper>
          <SectionWrapper
            heading={
              <HeadingSection id={headings[4].id}>
                {headings[4].title}
              </HeadingSection>
            }
          >
            <Node.p>
              The last, but not least of my requirements was presenting Notes
              with a look that I liked. I was very close to using Notion rather
              than making Dev Notes, as it had nearly every bit of functionality
              I would need. However, my desire to be picky with the visual
              aesthetic was strong enough to make my own tool. While this mostly
              boils down to my own subjective, personal preferences, I still
              want to share a bit about why I was getting so visually picky.
            </Node.p>
            <br />
            <Node.p>
              I typically use two monitors, and I like to keep something
              visually calm and simple on my secondary monitor when I'm not
              actively using it. I'm very sensitive to light and color, so
              leaving something that's brightly-colored or generally distracting
              on my secondary monitor can quickly overwhelm me. I was really
              hoping to make Dev Notes my new glorified desktop background for
              my secondary monitor that conveniently has a lot more utility than
              a desktop background. And while I know in general what visual
              styles are the least distracting to me, I also know that I can
              change my mind on exactly what styles I prefer. To avoid
              incessantly making minor code changes to update the look of Dev
              Notes later on, I decided to plan out a small settings form to
              modify my colors, font, and text size within the application
              itself:
            </Node.p>
            <Node.default style={{ margin: "20px 0" }}>
              <BorderWrapper style={{ maxWidth: "400px" }}>
                <Zoom>
                  <StaticImage
                    src="../../images/devnotes/SettingsWireframe.png"
                    alt="A mockup wireframe of a simple settings form with a select field for picking a color theme, another select field for fonts, and a slider field for setting a new base text size."
                  />
                </Zoom>
              </BorderWrapper>
            </Node.default>
          </SectionWrapper>
          <SectionWrapper
            heading={
              <HeadingSection id={headings[5].id}>
                {headings[5].title}
              </HeadingSection>
            }
          >
            <Node.p>
              In the end, I didn't make a lot of changes to the overall looks
              and functionality from the wireframes. The main changes I did make
              were either adjustments to specifically refine Dev Notes for
              myself, or flaws that became evident as I developed it. Here's how
              it ended up looking, along with some extra details pointing out
              some of the changes from the wireframes and why I made them:
            </Node.p>
            <Node.multiCol
              style={{ margin: "10px 0 20px 0" }}
              col1={
                <BorderWrapper caption="As stated in this image, an error is displayed in the form where the submission button usually appears. Complicating basic form elements, such as removing field labels or combining errors with the submission button, is generally considered bad practice and an accessibility issue. However, I was able to safely make these changes without negatively impacting the user experience, because I had the rare benefit of knowing the entire userbase (myself).">
                  <Zoom>
                    <StaticImage
                      src="../../images/devnotes/DevNotes5.jpg"
                      alt="The front page of a Dev Notes instance with a basic header, a simple form, a section with a Tag filters and search, and two placeholder example Notes that had been saved below. The header has one link called Dev Notes. The form has one submission button and four fields: a basic text field called Topic, a larger textarea field called Solution, an autocompleting select field to add additional Solutions, an additional autocompleting select field to create or add Tags. The submission button at the bottom currently displays the error: A Topic is required to create a Note. Only the titles and Tags of the two placeholder Notes can be seen. One of which is Tyrannosaurus with the Tag Cretaceous, and the other is Camarasaurus with the Tag Jurassic."
                    />
                  </Zoom>
                </BorderWrapper>
              }
              col2={
                <BorderWrapper caption="I added two buttons to adjust how Notes are sorted, as well as a separate Tag filtering component. Additionally, it quickly became clear that the heights of each Note needed to be limited to avoid hindering the ability to quickly look through Notes while searching. This is why the initial expand button was split into a more prominent vertical expand/collapse button (the center button at the bottom of each Note), and a full screen toggle.">
                  <Zoom>
                    <StaticImage
                      src="../../images/devnotes/DevNotes4.jpg"
                      alt="Another view of the front page of a Dev Notes, this time highlighting three Tag filters, and four example notes. The Tag filters are Dino Ipsum, Jurassic, and Cretaceous.. All of the text in the Notes are randomly generated real and fake dinosaur names. Each of the four Notes are are roughly the same size, and they all look very similar to the wireframes described earlier. The only addition is a new middle button in the actions at the bottom of a note. This new button is an icon with an arrow implying to expand or collapse the note vertically. The only note out of the four that stands out in any relevant way is the first one, as it's the only one that has been vertically expanded, thus making it the longest"
                    />
                  </Zoom>
                </BorderWrapper>
              }
            />

            <Node.multiCol
              style={{ margin: "10px 0 20px 0" }}
              col1={
                <BorderWrapper>
                  <Zoom>
                    <StaticImage
                      src="../../images/devnotes/ExampleNote.png"
                      alt="The same example mockup of an individual Note but in its final form. It's separated into a title section, a body section, and an actions section. The title has the text 'Example Note With Formatting' as well as a smaller piece of text representing a Tag named C#. The body has a bunch of placeholder text showing the various text formatting available that includes hyperlinks, inline code, code blocks, one type of heading, and a basic list. The actions section shows two simple buttons that have the text Expand, and Edit."
                    />
                  </Zoom>
                </BorderWrapper>
              }
              col2={
                <BorderWrapper>
                  <Zoom>
                    <StaticImage
                      src="../../images/devnotes/SettingsFormOnly.png"
                      alt="Another view of the front page of a Dev Notes, this time highlighting the basic settings form. The form has a simple layout with a single submission button with the text Save Changes, as well as the following fields: A select field called Theme with a currently selected theme called Too Bright that's represented by the color white and a very light gray; An autocompleting select field called Font with the font Open Sans currently selected; and a slider field called Base Text Size with the input currently set to 18px."
                    />
                  </Zoom>
                </BorderWrapper>
              }
            />
          </SectionWrapper>
          <SectionWrapper
            heading={
              <HeadingSection id={headings[6].id}>
                {headings[6].title}
              </HeadingSection>
            }
          >
            <Node.p>
              After using Dev Notes as a personal documentation tool for the
              past six months I can confidentially say I'm a fan. There are some
              elements I could refine here and there, but it ultimately improves
              on all the problems I set out to solve. Now I'll have to build a
              tool that — somehow — magically reminds me when I forget to
              document important findings…
            </Node.p>
          </SectionWrapper>
        </SectionWrapper>
      </Layout>
    </>
  )
}

export default DevNotesPage

export const Head: HeadFC = () => <Seo title={title} />
